<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">【攻略】条条线路通历思~</div>
      <p class="time">2020.10.14</p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/32-1.png')">
      </div>

      <p class="space_content" style="font-weight: 600">
        历思附近住宿推荐：
      </p>
      <p class="space_content" style="font-weight: 600">
        1、厦门柏曼酒店（湖里店)
      </p>

      <p class="space_content" >
        价格：300-900元
      </p>
      <p class="space_content" >
        地址：湖里区湖里大道24号太阳岛大厦(湖里大道与宜宾北路交叉口)
      </p>
      <p class="space_content" >
        电话：0592-8026788
      </p>

      <p class="space_content" style="font-weight: 600">
        2、厦门颐豪酒店（湖里店）
      </p>

      <p class="space_content" >
        价格：300-900元
      </p>
      <p class="space_content" >
        地址：湖里区湖里大道13号
      </p>
      <p class="space_content" >
        电话：0592-5757262
      </p>

      <p class="space_content" style="font-weight: 600">
        3、厦门悦华酒店（湖里店）
      </p>

      <p class="space_content" >
        价格：420-2500元
      </p>
      <p class="space_content" >
        地址：湖里区悦华路101号
      </p>
      <p class="space_content" >
        电话：0592-6023333
      </p>

      <p class="space_content" style="font-weight: 600">
        【注：以上价格仅供参考，酒店价格受季节等因素存在波动，以实际预订价格为准 】
      </p>



    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/31'"><p class="ellipsis1">庆贺！集美大学信息工程学院与历思“牵手”成功！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/33'"><p class="ellipsis1">中国药科大学狄斌教授一行莅临历思就“污水验毒”等技术进行研讨交流！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
